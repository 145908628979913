$hotpink: #ff00b3;
// rgb 255, 1, 203

$breakpoints: (
	"xsmall": 350px,
	"small": 450px,
	"medium": 768px,
	"large": 1024px,
	"xlarge": 1200px,
	"xxlarge": 1400px,
) !default;

@mixin breakpoint($point) {
	@if $point == xxlarge {
		@media (min-width: 1400px) {
			@content;
		}
	} @else if $point == xlarge {
		@media (min-width: 1200px) {
			@content;
		}
	} @else if $point == large {
		@media (min-width: 1024px) {
			@content;
		}
	} @else if $point == medium {
		@media (min-width: 768px) {
			@content;
		}
	} @else if $point == small {
		@media (min-width: 450px) {
			@content;
		}
	} @else if $point == xsmall {
		@media (max-width: 350px) {
			@content;
		}
	}
}

p,
li {
	font-size: 16px;
	@include breakpoint(medium) {
		font-size: 18px;
	}
}
figcaption {
	color: rgba(255, 255, 255, 0.5);
	margin: 5px 0px;
}
.highlight {
	color: $hotpink;
	font-weight: bold;
}
.page-heading {
	font-size: 2.5rem;
	color: $hotpink;
	font-family: "Rockout";
	text-align: center;
	letter-spacing: 3px;
}
.page-subheading {
	font-size: 1.5rem;
	color: $hotpink;
	font-family: "Lato";
}

@font-face {
	font-family: "Voice in my Head";
	src: url(./assets/fonts/Voice-in-my-Head.otf);
}

@font-face {
	font-family: "Brass Mono Cozy";
	src: url(./assets/fonts/BrassMono-Cozy.otf);
}
@font-face {
	font-family: "Brass Mono Regular";
	src: url(./assets/fonts/BrassMono-Regular.otf);
}
@font-face {
	font-family: "AmazOOSTROVInline";
	src: url(./assets/fonts/AmazOOSTROVInline.ttf);
}
@font-face {
	font-family: "Haunting Attraction";
	src: url(./assets/fonts/Haunting-Attraction.ttf);
}
@font-face {
	font-family: "Rockout";
	src: url(./assets/fonts/Rockout.otf);
}
@font-face {
	font-family: "Lato";
	src: url(./assets/fonts/Lato-Regular.ttf);
}
