@import "../../global.scss";

.about {
	min-height: fit-content;
	margin: 20px 0px 80px 0px;

	.about-container {
		width: 90%;
		margin: auto;
		padding-bottom: 20px;

		@include breakpoint(large) {
			width: 50%;
		}

		p,
		li {
			color: white;
			text-align: left;
		}

		figure {
			margin-top: 20px;
			@include breakpoint(medium) {
			}

			.photo-square {
				height: 250px;
				@include breakpoint(medium) {
					height: 350px;
				}
			}
		}
	}
}
