@import "../../global.scss";

.intro {
	height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: black;
	justify-content: center;
	align-items: center;
	position: relative;

	h2 {
		color: white;
		font-size: 2rem;
	}
	h2 span {
		font-family: "Brass Mono Regular";
	}
	.spiderweb-top {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 800px;
		overflow: hidden;
		opacity: 0.5;
		z-index: 0;
	}
	.cta {
		text-align: center;
		top: -150px;
		z-index: 1;
		.brand-intro-small {
			width: 100%;
			@include breakpoint(small) {
				height: 55px;
			}
			@include breakpoint(medium) {
				display: none;
			}
		}
		.brand-intro-large {
			display: none;
			@include breakpoint(medium) {
				display: block;
				height: 90px;
			}
		}
	}
	.intro-container {
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.btn-container {
			z-index: 1;
			display: flex;
			flex-direction: column;
			.btn-portfolio {
				background-color: black;
				padding: 10px;
				outline: none;
				border: none;
				.anchor-portfolio {
					border: solid 3px white;
					padding: 5px;
					color: white;
					text-decoration: none;
					font-size: 2rem;

					&:hover {
						cursor: pointer;
						color: $hotpink;
					}
				}
			}

			h3 {
				font-size: 1.3rem;
				text-align: center;
				margin-bottom: 0;
			}
		}
		.intro-para-container {
			.intro-para {
				color: white;
				max-width: 400px;
				padding: 5px;
				text-align: center;
			}
			.links-career {
				display: flex;
				justify-content: space-around;
				align-items: center;
				margin-top: 20px;
				h3 {
					font-size: 1.2rem;
				}
				.link-resume {
					color: $hotpink;
					text-decoration: none;

					&:hover {
						color: white;
					}
				}
				.link-linkedin {
					color: $hotpink;
					text-decoration: none;

					&:hover {
						color: white;
					}
				}
			}
		}
	}
}

//ityped animated cursor
.ityped-cursor {
	font-size: 2.2rem;
	opacity: 1;
	-webkit-animation: blink 0.3s infinite;
	-moz-animation: blink 0.3s infinite;
	animation: blink 0.3s infinite;
	animation-direction: alternate;
}

@keyframes blink {
	100% {
		opacity: 0;
	}
}

@-webkit-keyframes blink {
	100% {
		opacity: 0;
	}
}

@-moz-keyframes blink {
	100% {
		opacity: 0;
	}
}
//
