@import "../../global.scss";
.contact {
	background-color: rgb(0, 0, 0);
	color: white;
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
	margin-top: 20px;

	.spiderweb-bottom {
		width: 700px;
		bottom: 0px;
		right: 0px;
		position: absolute;
		opacity: 0.5;
		z-index: 1;
	}
	form {
		z-index: 2;
		min-height: fit-content;
		background-color: rgb(0, 0, 0);
		max-width: 400px;
		width: 90%;
		padding: 10px;
		display: flex;
		flex-direction: column;
		label {
			color: white;

			font-size: 1.5rem;
		}
		input {
			padding: 10px;
			font-size: 20px;
		}
		textarea {
			min-height: 200px;
			padding: 10px;
			font-size: 20px;
		}
		.submit {
			background-color: black;
			border: none;
			padding: 0;

			h2 {
				border: solid 3px white;
				margin: 0;
				color: white;
				font-size: 2rem;
				padding: 5px;
				
				&:hover {
					cursor: pointer;
					color: $hotpink;
				}
			}
		}
	}
}

.response {
	background-color: black;
	text-align: center;
	width: 100%;
	margin-top: 80px;
	@include breakpoint(medium) {
		height: 400px;
	}
	.response-text {
		color: white;
		margin: 20px 0px;
	}

	.photo-contact {
		height: 250px;
		@include breakpoint(medium) {
			height: 400px;
		}
	}
}
