@import "../../global.scss";

.navbar {
	background-color: black;
	color: white;
	width: 100%;
	height: 80px;
	// position: fixed;
	top: 0px;
	z-index: 10;
	overflow: hidden;
	padding-top: 0;
}
.container-navbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	@include breakpoint(small) {
		padding: 0px;
	}
}
.brand-small {
	margin: 3px;
	height: 40px;
	@include breakpoint(medium) {
		display: none;
	}
}

.brand-large {
	display: none;
	@include breakpoint(medium) {
		height: 60px;
		display: block;
	}
}
.brand {
	display: flex;
	align-items: center;
	letter-spacing: 0px;
	font-size: 1.5rem;
	text-decoration: none;
	font-family: "Rockout";
	color: white;
	letter-spacing: 5px;
	&:hover {
		color: $hotpink;
	}

	@include breakpoint(medium) {
		font-size: 2rem;
	}
	.logo-brand {
		height: 45px;
		margin: 0 1 0px;
	}
}

.left {
	display: flex;
	flex-direction: row;
	position: relative;
	text-align: center;
	padding-left: 10px;

	p {
		margin: 0;
	}
	.web-logo {
		height: 38px;
		margin: 0px 7px;
	}
	@include breakpoint(large) {
		display: flex;
		text-align: left;
	}
}
.contact-container {
	display: none;
	// align-items: center;
	// justify-content: center;
	// margin-left: 10px;
	// @include breakpoint(large) {
	// 	display: flex;
	// }
}
.icon-email {
	margin: 5px;
}
.right {
	.large-menu {
		display: none;
		@include breakpoint(medium) {
			display: block;
			padding-right: 2rem;
		}
		.large-menu-list {
			list-style-type: none;
			display: flex;
			padding-left: 0px;
		}
		.nav-link {
			color: white;
			&:hover {
				color: $hotpink;
			}
		}
	}
}
.hamburger {
	cursor: pointer;
	color: white;
	margin-right: 10px;
	z-index: 10;
	@include breakpoint(medium) {
		display: none;
	}
}
