@import "./global.scss";

.app {
	background-color: black;
	min-height: 100vh;
	width: 100vw;

	main {
		background-color: black;
		overflow-x: hidden;
		height: 100%;
		width: 95%;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 3rem;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
		scroll-behavior: smooth;
	}
}
