@import "../../global.scss";

.carousel {
	width: 100%;
	margin: auto;
	@include breakpoint(medium) {
		width: 90%;
	}
	@include breakpoint(large) {
		width: 70%;
	}
	.carousel-indicators {
		bottom: -40px;
	}
}
.carousel-item-container {
	display: block;
	justify-content: center;
	align-items: center;
	@include breakpoint(medium) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.carousel-left {
		background-color: black;
		display: flex;
		justify-content: center;
		align-items: center;
		.carousel-img {
			height: 250px;
			@include breakpoint(medium) {
				height: 400px;
			}
		}
	}

	.carousel-right {
		background-color: black;
		padding: 20px;
		color: white;

		overflow-y: hidden;
		width: 100%;
		@include breakpoint(medium) {
			padding: 5px;
			margin-left: 20px;
			width: 80%;
		}
	}
}
.carousel-control-next,
.carousel-control-prev {
	opacity: 0.7 !important;
	top: 50px !important;
	align-items: inherit !important;
	overflow-x: visible !important;
	height: 200px;
	@include breakpoint(medium) {
		top: -40px !important;
	}
	&:hover {
		opacity: 1 !important;
	}
}

.carousel-control-next-icon {
	background-image: url(../../assets/arrow-next-pink.svg) !important;
	width: 2rem !important;
	height: 2rem !important;
	opacity: 1 !important;
	z-index: 99999 !important;
	overflow-x: visible !important;
}
.carousel-control-prev-icon {
	background-image: url(../../assets/arrow-next-pink.svg) !important;
	transform: rotate(180deg);
	width: 2rem !important;
	height: 2rem !important;
	opacity: 1;
	z-index: 99999 !important;
	overflow-x: visible !important;
}

.container-description {
	flex: 1;
	overflow-y: hidden;
	p {
		margin: 10px 0px;
	}
	a {
		color: $hotpink;
		&:hover {
			color: white;
		}
	}
}

.heading-project {
	font-size: 2rem;
	color: white;
	display: flex;
}
.subheading-project {
	font-size: 1.5rem;
	font-family: "Brass Mono";
	width: 100%;
}

.container-features {
	flex: 2;
	overflow-y: hidden;
}

.features-div {
	overflow-y: hidden;

	.logo-container {
		display: flex;
		justify-content: space-between;
		padding: 5px;
	}
	li a {
		color: white;
		&:hover {
			color: $hotpink;
		}
	}
}
.logo-container {
	display: flex;
	justify-content: space-between;
	padding: 5px;
}

.icon-program {
	max-width: 30px;
	max-height: 30px;
}
.icon-program-wide {
	max-width: 50px;
	max-height: 30px;
}
.logo-project {
	height: 40px;
	margin-left: 20px;
}

.links-container {
	display: flex;
	a {
		padding: 5px;
		color: $hotpink;
	}
	&:hover {
		color: white;
	}
}
.link-project {
	a:hover {
		color: white;
	}
}
